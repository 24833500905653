import React from "react";
import styles from "./styles.module.css";
import UpdateVoucher from "../UpdateVoucher";
import { Typography, Box } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import useVoucher from "../hooks/useVoucher";
const ShowVouchers = () => {
  const {
    vouchers,
    courses,
    openChange,
    voucherToUpdate,
    setOpenChange,
    deleteVoucher,
    updateVoucher,
    openUpdateForm,
  } = useVoucher();

  // Mapping des vouchers pour affichage
  const vouchersList = vouchers.map((voucher) => (
    <div className={styles.CardVoucher} key={voucher._id}>
      <div className={styles.CardBody}>
        <div className={styles.Config}>
          <DeleteOutlineIcon
            className={styles.icons}
            onClick={() => deleteVoucher(voucher._id)}
          />
          <EditIcon
            className={styles.icons}
            onClick={() => openUpdateForm(voucher)}
          />
        </div>
        <div className={styles.Details}>
          <Typography variant="h6">
            Link: <span>{voucher.link}</span>
          </Typography>
          <Typography variant="h6">
            Code: <span>{voucher.code}</span>
          </Typography>
        </div>
      </div>
    </div>
  ));

  return (
    <React.Fragment>
      {voucherToUpdate && (
        <UpdateVoucher
         courses={courses}
          voucher={voucherToUpdate}
          updateVoucher={updateVoucher}
          openChange={openChange}
          setOpenChange={setOpenChange}
        />
      )}
      <div className={styles.ShowVouchers}>
        <h1>Vouchers</h1>
        <div className={styles.Cards}>{vouchersList}</div>
      </div>
    </React.Fragment>
  );
};

export default ShowVouchers;
